import React from "react";
import "./App.css";
import { Helmet } from "react-helmet";

class App extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <html lang="en" />
          <title>Ernie's Pizza</title>
          <meta
            name="description"
            content="We simply make it fresh and best!"
          />
          <meta name="theme-color" content="#E6E6FA" />
          <meta property="og:title" content="Ernie's Pizza" />
          <meta
            property="og:description"
            content="We simply make it fresh and best!"
          />
          <meta property="og:image" content="assets/images/slider2.jpg" />
        </Helmet>

        <div id="Home">
          <nav className="navbar navbar-expand-lg navbar fixed-top  navbar-light bg-light">
            <a className="navbar-brand" href="#Home">
              <img
                src="assets/images/head.png"
                width="50"
                height="50"
                className="d-inline-block"
                alt=""
              />{" "}
              Ernie's Pizza and Bakeshop
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarText">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="#Home">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#AboutUs">
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="#Menu">
                    Menu
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#ContactUs">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="d-block w-100 img-fluid img-slider"
                  src="assets/images/slider1.jpg"
                  alt="First slide"
                />
                <div className="carousel-caption">
                  <h2>The True Taste of Catbalogan!</h2>
                  <p>...</p>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100 img-fluid img-slider"
                  src="assets/images/slider2.jpg"
                  alt="Second slide"
                />
                <div className="carousel-caption">
                  <h2>Catbalogan's Finest!</h2>
                  <p>...</p>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  className="d-block w-100 img-fluid img-slider"
                  src="assets/images/slider3.jpg"
                  alt="Third slide"
                />
                <div className="carousel-caption">
                  <h2>We Simply Make it Fresh and Best!</h2>
                  <p>...</p>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div className="container">
          <div className="row" id="AboutUs">
            <div className="col navMenu">
              <h2 className="text-center">~ About Us ~</h2>
            </div>
          </div>
          <div className="row bg-light">
            <div className="col-md-6 mt-2 text-justify">
              <h3>History</h3>
              <p>
                Ernie's Pizza's beginnings started in 1990, when Ernesto "Ernie"
                R. Singzon, an architecture student in National University,
                dropped out of school after the death of his father, and needed
                to take care of his family. In 1991, a year after his father's
                death, he wanted to try his hand in the food service industry.
                He started to make pizza in the kitchen of his home, set up a
                table in the living room for people to eat on, and took orders
                and cooked the food on his own. He started by inviting his
                friends to come and try out his food, and had a made to order
                setup, he would open and close the restaurant depending wether
                there is a customer. After a year of this setup, the restaurant
                started to expand, and he hired people for help, and used most
                of his home as his restaurant. In 1992, Ernie's Pizza officially
                opened as a full fledged restaurant and has continued to
                flourish ever since. It become a favorite spot for the people of
                Catbalogan, to eat and hang-out, and with plans to expand more
                in the future.
              </p>
            </div>
            <div className="col-md-6" data-aos="fade-up">
              <img
                alt="location"
                className="img-fluid"
                src="assets/images/ernies.jpeg"
              />
            </div>
          </div>
          <div className="row bg-light">
            <br />
          </div>
          <div className="row bg-light">
            <div className="col-md-6 order-md-1 order-2" data-aos="fade-up">
              <img
                alt="cuisine"
                className="img-fluid "
                src="assets/images/cuisine.jpg"
              />
            </div>
            <div className="col-md-6 order-md-12 order-1 text-justify">
              <h3>Mission</h3>
              <p>
                To provide quality and delicious food, and excellent service at
                a reasonable price, and to understand the customers' ever
                changing needs, to constantly improve the customer's experience,
                and to enhance our employee's excellence, continuous development
                and happiness.
              </p>
              <h3>Vision</h3>
              <p>
                To become profitable in order to thrive as a business, and
                expand for future ventures, and provide the customers with
                happiness through excellent service and food, and to grow and
                become profitable to become a better Ernie's Pizza each day.
              </p>
            </div>
          </div>
          <div className="row" id="Menu">
            <div className="col navMenu">
              <h2 className="text-center">~ Menu ~</h2>
            </div>
          </div>
          <div className="row bg-light">
            <div className="col-md-4 mt" data-aos="slide-up">
              <div className="card view zoom">
                <img
                  alt="meat-menu"
                  className="card-img-top img-fluid "
                  src="assets/images/pizza.jpg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Pizza</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Ham & Pineapple - Small</div>
                      <div className="right">P50</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Ham & Pineapple - Medium</div>
                      <div className="right">P80</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Ham & Pineapple - Large</div>
                      <div className="right">P90</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Mozzarella Pizza - Medium</div>
                      <div className="right">P125</div>
                      <div className="clear desc">
                        Choice of Beef & Mushroom, Ham & Pineapple, Pepperoni
                        with Mushroom
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Mozzarella Pizza - Large</div>
                      <div className="right">P140</div>
                      <div className="clear desc">
                        Choice of Beef & Mushroom, Ham & Pineapple, Pepperoni
                        with Mushroom
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Mozzarella Pizza - Thin</div>
                      <div className="right">P170</div>
                      <div className="clear desc">
                        Choice of Beef & Mushroom, Ham & Pineapple, Pepperoni
                        with Mushroom
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Mozzarella Pizza - Pan</div>
                      <div className="right">P190</div>
                      <div className="clear desc">
                        Choice of Beef & Mushroom, Ham & Pineapple, Pepperoni
                        with Mushroom
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt" data-aos="slide-up">
              <div className="card">
                <img
                  alt="fish"
                  className="card-img-top img-fluid "
                  src="assets/images/specialty-pizza.jpg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Specialty Pizza</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Hungarian Sausage Pizza</div>
                      <div className="right">P130</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Sisig Pizza</div>
                      <div className="right">P130</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Carbonara Pizza</div>
                      <div className="right">P140</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Palabok Pizza</div>
                      <div className="right">P270</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Pepperoni Pizza</div>
                      <div className="right">P280</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Italian Pizza</div>
                      <div className="right">P280</div>
                      <div className="clear desc">
                        Choice of All Cheese, All Meat,{" "}
                        <strike>and Tinapa</strike>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Calzone</div>
                      <div className="right">P270</div>
                      <div className="clear desc">Folded Pizza</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Dinner-roll Pizza</div>
                      <div className="right">P210</div>
                      <div className="clear desc">Pull-apart pizza</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Mezza Luna Pizza</div>
                      <div className="right">P290</div>
                      <div className="clear desc">
                        Half calzone, half chicken barbeque pizza
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Chicken Barbeque Pizza</div>
                      <div className="right">P280</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Il Papillon Pizza</div>
                      <div className="right">P300</div>
                      <div className="clear desc">
                        Three flavors in one. Pepperoni, Beef & Mushroom, and
                        Ham & Pineapple
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt" data-aos="slide-up">
              <div className="card view zoom">
                <img
                  alt="japanese"
                  className="card-img-top img-fluid"
                  src="assets/images/japanese.png"
                />
                <div className="card-body">
                  <h4 className="card-title center">Japanese Bowls</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Curry Bowl</div>
                      <div className="right">P160</div>
                      <div className="clear desc">
                        Deep fried pork cutlet in rich japanese curry sauce with
                        deep fried carrots.
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Sisig Bowl</div>
                      <div className="right">P150</div>
                      <div className="clear desc">
                        Pork sisig with donburi sauce topped with fried egg.
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Tonkatsudon</div>
                      <div className="right">P160</div>
                      <div className="clear desc">
                        Deep fried crumbed pork cutlet with onions with egg over
                        rice.
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Teri-Tonkatsudon</div>
                      <div className="right">P170</div>
                      <div className="clear desc">
                        Deep fried crumbed pork cutlet with onions with egg over
                        rice in teriyaki sauce.
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Torikatsudon</div>
                      <div className="right">P155</div>
                      <div className="clear desc">
                        Deep fried crumbed chicken cutlet with onions with egg
                        over rice.
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Toriteriyakidon</div>
                      <div className="right">P165</div>
                      <div className="clear desc">
                        Deep fried crumbed chicken cutlet with onions with egg
                        over rice in teriyaki sauce.
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt" data-aos="slide-up">
              <div className="card">
                <img
                  alt="vegetarian"
                  className="card-img-top img-fluid"
                  src="assets/images/caldereta.jpg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Beef</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Caldereta</div>
                      <div className="right">P150</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Burger Steak</div>
                      <div className="right">P73</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt" data-aos="slide-up">
              <div className="card">
                <img
                  alt="vegetarian"
                  className="card-img-top img-fluid"
                  src="assets/images/bangus.jpg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Fish</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Deboned Bangus</div>
                      <div className="right">P129</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Sisig Bangus</div>
                      <div className="right">P123</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt" data-aos="slide-up">
              <div className="card">
                <img
                  alt="vegetarian"
                  className="card-img-top img-fluid"
                  src="assets/images/chix-spag.jpg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Value Meal</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Spaghetti with Chicken</div>
                      <div className="right">P139</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt" data-aos="slide-up">
              <div className="card">
                <img
                  alt="vegetarian"
                  className="card-img-top img-fluid"
                  src="assets/images/tonkatsu.jpg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Pork</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Pork Chop</div>
                      <div className="right">P98</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Pork Sisig</div>
                      <div className="right">P103</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Pork Tenderloin</div>
                      <div className="right">P93</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Buffalo Pork Tenderloin</div>
                      <div className="right">P98</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Tonkatsu</div>
                      <div className="right">P118</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Lumpiang Shanghai</div>
                      <div className="right">P68</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">1pc Pork Barbeque</div>
                      <div className="right">P58</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">2pcs Pork Barbeque</div>
                      <div className="right">P88</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt" data-aos="slide-up">
              <div className="card">
                <img
                  alt="vegetarian"
                  className="card-img-top img-fluid"
                  src="assets/images/sizzling-chix.jpg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Chicken</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Chicken Inasal</div>
                      <div className="right">P89</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Chicken Barbeque</div>
                      <div className="right">P89</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Baked Chicken</div>
                      <div className="right">P84</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Fried Chicken</div>
                      <div className="right">P84</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">2pcs Fried Chicken</div>
                      <div className="right">P144</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Sizzling Chicken</div>
                      <div className="right">P94</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Chicken Fillet</div>
                      <div className="right">P94</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Buffalo Chicken</div>
                      <div className="right">P94</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Chick 'n Cheese</div>
                      <div className="right">P94</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Chicken Teriyaki</div>
                      <div className="right">P99</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Soy Garlic Chicken</div>
                      <div className="right">P99</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Chicken Bucket</div>
                      <div className="right">P495</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt" data-aos="slide-up">
              <div className="card">
                <img
                  alt="vegetarian"
                  className="card-img-top img-fluid"
                  src="assets/images/chili-dog.jpg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Sandwiches</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Tuna Bunwich</div>
                      <div className="right">P53</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Cheesy Dog</div>
                      <div className="right">P48</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Chili Cheese Dog</div>
                      <div className="right">P68</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Hamburger</div>
                      <div className="right">P45</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Cheeseburger</div>
                      <div className="right">P51</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Chicken Burger</div>
                      <div className="right">P58</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Bacon BBQ Cheeseburger</div>
                      <div className="right">P73</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Siopao Asado</div>
                      <div className="right">P41</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt" data-aos="slide-up">
              <div className="card">
                <img
                  alt="vegetarian"
                  className="card-img-top img-fluid"
                  src="assets/images/lomi.jpg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Noodles</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Lomi</div>
                      <div className="right">P60</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Palabok</div>
                      <div className="right">P65</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Bihon Solo</div>
                      <div className="right">P65</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Bihon</div>
                      <div className="right">P210</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt" data-aos="slide-up">
              <div className="card">
                <img
                  alt="vegetarian"
                  className="card-img-top img-fluid"
                  src="assets/images/spaghetti.jpg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Pasta</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Spaghetti</div>
                      <div className="right">P60</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Carbonara</div>
                      <div className="right">P60</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt" data-aos="slide-up">
              <div className="card">
                <img
                  alt="vegetarian"
                  className="card-img-top img-fluid"
                  src="assets/images/fries.jpeg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Sides</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">French Fries</div>
                      <div className="right">P48</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Plain Rice</div>
                      <div className="right">P15</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt" data-aos="slide-up">
              <div className="card">
                <img
                  alt="vegetarian"
                  className="card-img-top img-fluid"
                  src="assets/images/halohalo.jpg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Dessert</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Fruit Salad</div>
                      <div className="right">P35</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Halo-halo</div>
                      <div className="right">P55</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Special Halo-halo</div>
                      <div className="right">P70</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Ube Macapuno</div>
                      <div className="right">P60</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Maiz con Hielo</div>
                      <div className="right">P40</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt mb" data-aos="slide-up">
              <div className="card">
                <img
                  alt="vegetarian"
                  className="card-img-top img-fluid"
                  src="assets/images/shakes.jpg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Shakes</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Mango</div>
                      <div className="right">P45</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Avocado</div>
                      <div className="right">P45</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Pineapple</div>
                      <div className="right">P45</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Buko</div>
                      <div className="right">P45</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Maiz</div>
                      <div className="right">P40</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Ube</div>
                      <div className="right">P50</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Strawberry</div>
                      <div className="right">P65</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt mb" data-aos="slide-up">
              <div className="card">
                <img
                  alt="vegetarian"
                  className="card-img-top img-fluid"
                  src="assets/images/drinks.jpg"
                />
                <div className="card-body">
                  <h4 className="card-title center">Drinks</h4>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Softdrinks - 12oz</div>
                      <div className="right">P20</div>
                      <div className="clear desc">Coke, Royal, Sprite</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Softdrinks - 16oz</div>
                      <div className="right">P25</div>
                      <div className="clear desc">Coke, Royal, Sprite</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Pineapple Juice</div>
                      <div className="right">P45</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Four Seasons Juice</div>
                      <div className="right">P45</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Iced Tea - 12oz</div>
                      <div className="right">P25</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Iced Tea - 16oz</div>
                      <div className="right">P30</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Cucumber Tea - 12oz</div>
                      <div className="right">P25</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Cucumber Tea - 16oz</div>
                      <div className="right">P30</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Bottled Water</div>
                      <div className="right">P12</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col mt mb" data-aos="slide-up">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title center">Container Charges</h4>
                  <p className="text-center clear desc">
                    Menu prices are inclusive of container charges except for
                    eco bags.
                  </p>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <div className="left">Burger</div>
                      <div className="right">P1</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Sandwiches / Fries</div>
                      <div className="right">P3</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Pasta / Noodles / Halo-halo</div>
                      <div className="right">P5</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Rice Meals</div>
                      <div className="right">P8</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Bangus / Chicken Meals</div>
                      <div className="right">P9</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">
                        Japanese Bowl / Caldereta / Bihon
                      </div>
                      <div className="right">P10</div>
                    </li>
                    <li className="list-group-item">
                      <h4 className="card-title center">Eco Bag</h4>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Eco Bag - Small</div>
                      <div className="right">P3</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Eco Bag - Medium</div>
                      <div className="right">P4</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Eco Bag - Large</div>
                      <div className="right">P5</div>
                    </li>
                    <li className="list-group-item">
                      <div className="left">Eco Bag - Extra Large</div>
                      <div className="right">P6</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row" id="ContactUs">
            <div className="col navMenu">
              <h2 className="text-center">~ Contact Us! ~</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h3>Address:</h3>
              <p>Rizal Ave. Ext. Brgy. 12, Catbalogan City, Samar, 6700</p>
              <h3>Email:</h3>
              <p>support@erniespizza.ph</p>
            </div>
            <div className="col-md-3">
              <h3>Globe:</h3>
              <p>09675579918</p>
              <br></br>
              <h3>Smart:</h3>
              <p>09088918495</p>
            </div>
            <div className="col-md-3">
              <h3>GCash:</h3>
              <h4>09178342981</h4>
              <img
                alt="vegetarian"
                className="img-fluid"
                src="assets/images/gcash-ernie.jpg"
              />
            </div>
            <div className="col-md-3">
              <h3>Maya:</h3>
              <h4>09176544297</h4>
              <img
                alt="vegetarian"
                className="img-fluid"
                src="assets/images/maya.jpg"
              />
            </div>
          </div>
          <div className="row footer bg-light">
            <div className="col">
              <a
                className="social-icon"
                href="https://www.facebook.com/erniespizzaph"
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                className="social-icon"
                href="https://www.instagram.com/erniespizzaph/"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                className="social-icon"
                href="https://www.twitter.com/erniespizzaph/"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </div>
            <div className="col">
              <p className="text-center">Copyright &copy; 2022</p>
            </div>
            <div className="col">
              <p className="text-center">Powered by: TerraByte Solutions</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default App;
